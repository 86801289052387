import React from 'react'
import SectionTitle from "../components/common/SectionTitle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Form from "../components/common/Form";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";

const Contact = () => {
  return (
    <div>
      <div className="w-full p-10 text-center bg-gray-900">
        <SectionTitle
          text="REACH OUT TO US FOR WEBSITE DEVELOPMENT"
          color="text-white"
        />
        <div className="w-full flex flex-col md:flex-row justify-start">
          <div className="w-full md:w-1/2">
            {/* <SectionTitle text="Let's chat and create a website that converts!!!" color="text-white"/> */}
            <SectionTitle text="Contact Us Today!" color="text-lime-500" />
            <div className="p-6">
              <div className="">
                <FaPhone className="inline-block text-lime-500 text-2xl m-5 hover:scale-125 transition duration-300" />
                <h2 className="text-sm md:text-lg text-white mb-6 max-w-screen-xl mx-auto">
                  <a href="tel:+916353107643">+91 63531 07643</a>
                </h2>
              </div>
              <div>
                <IoMdMail className="inline-block text-lime-500 text-2xl m-5 hover:scale-125 transition duration-300" />
                <h2 className="text-sm md:text-lg text-white mb-6 max-w-screen-xl mx-auto">
                  <a href="mailto:Info.caxtra@gmail.com">
                    Info.caxtra@gmail.com
                  </a>
                </h2>
              </div>
              <div>
                <FaLocationDot className="inline-block text-lime-500 text-2xl m-5 hover:scale-125 transition duration-300" />
                <h2 className="text-sm md:text-lg text-white mb-6 max-w-screen-xl mx-auto">
                  10, Agrawal Mall, Sarkhej - Gandhinagar Highway,
                  <br />
                  Gota, Ahmedabad, Gujarat 380060
                </h2>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center ">
            <div className="containter flex justify-center flex-col md:w-3/4">
              <Form />
              <div className="bg-white w-full p-10 sticky mt-[-100px]  md:mt-[-85px] white-panel  rounded-xl"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
