import React, { useState } from 'react'
import Testimonial from './Testimonial'
import SectionTitle from './SectionTitle';

const ClientTestimonial = ({ testimonials }) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    
  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

return (
    <div className='w-full text-center px-10 py-20 bg-black'>
    <p>TESTIMONIALS</p>
    <SectionTitle text="Our Client’s Feedbacks"/>
    <div className="flex items-center justify-center gap-4 pt-10 pb-5 ">
            {testimonials.length > 1 && <button onClick={prevSlide}>&#10094;</button>}
            {testimonials.map((service, index) => (
               <Testimonial 
               key={index}
               textAlign="center"
               image={service.image}
               companyName={service.companyName}
               owner={service.owner}
               description={service.description}
             />
            ))}
            {testimonials.length > 1 && <button onClick={nextSlide}>&#10095;</button>}
        </div>
    </div>
)
}

export default ClientTestimonial
