import React from 'react'
import MainSection from '../components/common/MainSection'

const About = () => {
  return (
    <div className='w-full'>
      <MainSection 
        title="About Caxtra"
        text="Elevate with Custom Websites, Innovative Software, and Captivating Designs for Unparalleled Business Success"
        buttonName="Contact"
        sectionImage="/images/about-banner.png"
        leftImage
      />
    </div>
  )
}

export default About
