import React, { useEffect, useState } from "react";
import SectionTitle from "../components/common/SectionTitle";
import Button from "../components/common/Button";
import MainSection from "../components/common/MainSection";
import ImageBox from "../components/common/ImageBox";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Accordion from "../components/common/Accordion";
import { useNavigate } from "react-router-dom";
import Form from "../components/common/Form";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { Helmet } from 'react-helmet';

const Lead = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    // Initial check on component mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const categories = [
    { name: "Ecommerce", URL: "/images/E-commerce.png" },
    { name: "Creative Industries", URL: "/images/Creative Industries.png" },
    {
      name: "Education & Non-Profits",
      URL: "/images/Education & Non-Profits.png",
    },
    { name: "Professional Services", URL: "/images/Professional Services.png" },
    {
      name: "Restaurants  & Hospitality",
      URL: "/images/Restaurants  & Hospitality.png",
    },
    { name: "Local Businesses", URL: "/images/Local Businesses.png" },
  ];

  const slider = [
    "/images/template/1.gif",
    "/images/template/2.gif",
    "/images/template/3.gif",
    "/images/template/4.gif",
    "/images/template/5.gif",
    "/images/template/6.gif",
    "/images/template/7.gif",
    "/images/template/8.gif",
    "/images/template/9.gif",
    "/images/template/10.gif",
    "/images/template/11.gif",
    "/images/template/12.gif",
    "/images/template/13.gif",
    "/images/template/14.gif",
    "/images/template/15.gif",
    "/images/template/16.gif",
    "/images/template/17.gif",
  ];
  const sliderSettings = {
    dots: !isMobile,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // Medium screens and above (PC and laptop)
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 640, // Small screens (mobile)
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const ourServicesSettings = {
    dots: !isMobile,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const accordionData = [
    {
      title:
        "Why To Choose AmazingWeb.Design for Android App Development and Website Development?",
      content: `We are an award-winning website and app development company with over 2,000 clients and a 97% retention rate. We are a team of expert Android developers, iOS developers, Shopify and WordPress store developers and rated among top eCommerce software development agency.`,
    },
    {
      title:
        "How much does it cost to make an app in India? / What is Android app making cost in India?",
      content: `The first question that comes to mind when someone plans to start website or mobile app development is "How much does it cost to make an app in India?. With India's No.1 Mobile Applications Developer, you can create your own android + iOS mobile app for ₹ 9,999`,
    },
    {
      title: "How to contact with Customer Service?",
      content: `You can contact our customer service specialist 24*7 via live chat. You can also raise a ticket by logging-in your account or connect with us through e-mail (hello@amazingweb.design).`,
    },
  ];

  return (
    <div className="w-full">
       <Helmet>
        <title>Website Design & Development Services At only 4999/-</title>
        <meta name="description" content="Boost sales & leads with a converting website. Get expert website development services at an affordable price. Contact us today!" />
       <meta name="title" content="Website Design & Development Services At only 4999/-" />
       <meta property="og:url" content="https://caxtra.com/website-design-and-development-lead-generation" />
      </Helmet>
      <div className="w-full p-8 md:h-[900px] md:p-14 bg-black text-center">
        <div
          className="w-50 sm:w-60 mx-auto my-6 md:my-8"
          onClick={() => navigate("/")}
        >
          <img
            src="/images/CAXTRA.png"
            alt="Logo"
            className="h-full w-full cursor-pointer"
          />
        </div>
        <SectionTitle
          text="Website Design & Development Services Starting At only 4999/- + 1 Time Free SEO"
          color="text-white"
        />
        <h2 className="text-sm md:text-lg text-white mb-6 pointer-events-none max-w-screen-xl mx-auto">
          Level up your online presence with a website that converts. We design
          websites that are beautiful, functional, and drive results. Get found
          by more customers with a mobile-friendly and search engine optimized
          website that showcases your brand and compels visitors to take action.
        </h2>
        <Button
          text="Let's connect"
          color="green"
          link="https://wa.me/6353107643?text=Hello%21%20I%27m%20interested%20in%20your%20IT%20services.%20Could%20you%20please%20provide%20more%20information%3F%20Thank%20you%21"
        />
        <div className="flex gap-2 flex-wrap justify-between items-center max-w-screen-xl md:mx-auto m-2 md:m-0">
          {categories.map((c) => (
            <div
              key={c.name}
              className="inline-block w-[31%] md:w-[14%] h-[16%] max-w-48 my-0 md:my-5 overflow-hidden rounded-md"
            >
              <img src={c.URL} alt={c.name} className="h-full w-full" />
            </div>
          ))}
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-screen-2xl mx-auto">
          <div className="w-full py-2 md:px-8 p-8 text-center">
            <SectionTitle
              text="EVERYTHING YOU NEED FOR YOU WEBSITE"
              color="text-black"
            />
            {/* <SectionTitle text="sell & grow online" color="text-lime-500"/> */}
          </div>
          <div className="py-4 md:pb-8 px-7 md:px-7 pb-7">
            <Slider {...sliderSettings}>
              {slider.map((i) => (
                <div key={i} className="w-32">
                  <img src={i} alt={i} />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* <MainSection 
            title="Seamless Store Management"
            text="Easily control your business with powerful analytics & advanced functionalities like Add access to staff members, set store timings, delivery charges, an option to integrate delivery agent app, vendor app, live location Tracking and so much more.Elevate with Custom Websites, Innovative Software, and Captivating Designs for Unparalleled Business Success"
            lead
            sectionImage="/images/manage.jpg"
          /> */}
      </div>
      {/* <div className='w-full p-10 text-center'>
            <SectionTitle text="Made To Integrate"  color="text-lime-500" />
            <SectionTitle text="Built on Flutter. Build For Everyone"/>
        </div> */}
      {/* <div className='w-full justify-between p-10 flex flex-wrap gap-6 my-18 max-w-screen-2xl mx-auto '>
        <ImageBox 
          lead
          image="/images/wordpress.png"
          title="WordPress"
          text="WooCommerce is a customizable, open-source eCommerce platform built on WordPress. Convert your WordPress website into Native Android + iOS App. Rated #1 Mobile Application Development Company & Best Android App Developer*"
        />
        <ImageBox
        lead
          image="/images/shopify1.png"
          title="Shopify"
          text="No.1 eCommerce Platform for All Businesses, Shopify is the perfect solution for your online store. Trusted by Millions of Businesses, we are a certified Shopify partner for Shopify Store Development & Shopify App Development."
        />
        <ImageBox 
        lead
          image="/images/mmm.png"
          title="Magento"
          text="Get all types of Magento development solutions with us. Make App and connect it your existing Magento 2.0 Store with Us. We are a Certified Magento 2 Development Company for Website and App Development."
        />
      </div> */}
      <div className="bg-gray-900">
        <MainSection
          smallTitle="ARE YOU A BUSINESS OWNER IN ONE OF THESE SITUATIONS?"
          title="Taking Your Business Online:"
          text="You're just starting out and haven't built a website yet. break You rely solely on social media or word-of-mouth, but want to expand your reach. break Your current website is outdated or non-existent on mobile devices."
          buttonName="BUID YOUR WEBSITE NOW"
          link="https://wa.me/6353107643?text=Hello%21%20I%27m%20interested%20in%20your%20IT%20services.%20Could%20you%20please%20provide%20more%20information%3F%20Thank%20you%21"
          sectionImage="/images/Taking-Your-Business-Online.png"
        />
      </div>
      <div className="w-full p-10 my-18 ">
        <div className="d-flex text-center">
          <SectionTitle
            text="WHY YOUR BUSINESS NEEDS A WEBSITE?"
            color="text-white"
          />
        </div>
        <div className="flex flex-wrap gap-6 justify-between py-5">
          <ImageBox
            textAlign="center"
            image="/images/icon_easy_customize.svg"
            title="Lack Of Online Visibility"
            text=" In today's digital age, a website is essential for establishing brand credibility and attracting customers."
          />
          <ImageBox
            image="/images/icon_mobile_first.svg"
            title="Missed Lead & Sales opportunities"
            text="Without a website, you're missing out on potential leads and sales from people searching online for your products or services."
          />
          <ImageBox
            image="/images/icon_pagebuilder.svg"
            title="Limited Marketing Reach"
            text="Social media is great, but a website allows you to control your brand narrative and implement broader marketing strategies."
          />
        </div>
      </div>

      <section className="min-h-screen bg-gray-900 text-center py-20 px-8 xl:px-0 flex flex-col justify-center">
        {/* <h1 className="text-white text-4xl md:text-5xl xl:text-6xl font-semibold max-w-3xl mx-auto mb-16 leading-snug">Services Built Specifically for your Business</h1> */}
        <SectionTitle
          text="OUR SOLUTION: WEBSITES BUILT FOR BUSINESS GROWTH"
          color="text-white"
        />
        <span className="text-gray-400 text-lg mx-auto mb-10 capitalize flex items-center">
          We specialize in crafting websites specifically for businesses like
          yours. Here's what we offer:
        </span>

        <div className="grid-offer text-left grid sm:grid-cols-2 md:grid-cols-2 gap-5 max-w-5xl mx-auto">
          <div className="card bg-gray-800 p-10 relative">
            <div className="circle"></div>
            <div className="relative lg:pr-52">
              <h2 className="capitalize text-white mb-4 text-2xl xl:text-3xl">
                Website Creation from Scratch
              </h2>
              <p className="text-gray-400">
                No website? No problem! We'll guide you through the entire
                process, from concept to launch.
              </p>
            </div>
            <div className="icon"></div>
          </div>
          <div className="card bg-gray-800 p-10 relative">
            <div className="circle"></div>
            <div className="relative lg:pl-48">
              <h2 className="capitalize text-white mb-4 text-2xl xl:text-3xl">
                Mobile-First Design
              </h2>
              <p className="text-gray-400">
                Today's users browse primarily on phones. We ensure your website
                looks amazing and functions flawlessly on all devices.
              </p>
            </div>
          </div>
          <div className="card bg-gray-800 p-10 relative">
            <div className="circle"></div>
            <div className="relative lg:pr-44">
              <h2 className="capitalize text-white mb-4 text-2xl xl:text-3xl">
                User-Friendly Navigation
              </h2>
              <p className="text-gray-400">
                Confusing website layouts drive visitors away. We prioritize
                intuitive navigation to keep users engaged.
              </p>
            </div>
          </div>
          <div className="card bg-gray-800 p-10 relative">
            <div className="circle"></div>
            <div className="relative lg:pl-48">
              <h2 className="capitalize text-white mb-4 text-2xl xl:text-3xl">
                Content & SEO Optimization
              </h2>
              <p className="text-gray-400">
                Attract organic traffic through strategic content creation and
                search engine optimization strategies.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="w-full p-10 text-center">
        <SectionTitle
          text="REACH OUT TO US FOR WEBSITE DEVELOPMENT"
          color="text-white"
        />
        <div className="w-full flex flex-col md:flex-row justify-start">
          <div className="w-full md:w-1/2">
            {/* <SectionTitle text="Let's chat and create a website that converts!!!" color="text-white"/> */}
            <SectionTitle text="Contact Us Today!" color="text-lime-500" />
            <div className="p-6">
              <div className="">
                <FaPhone className="inline-block text-lime-500 text-2xl m-5 hover:scale-125 transition duration-300" />
                <h2 className="text-sm md:text-lg text-white mb-6 max-w-screen-xl mx-auto">
                  <a href="tel:+916353107643">+91 63531 07643</a>
                </h2>
              </div>
              <div>
                <IoMdMail className="inline-block text-lime-500 text-2xl m-5 hover:scale-125 transition duration-300" />
                <h2 className="text-sm md:text-lg text-white mb-6 max-w-screen-xl mx-auto">
                  <a href="mailto:Info.caxtra@gmail.com">
                    Info.caxtra@gmail.com
                  </a>
                </h2>
              </div>
              <div>
                <FaLocationDot className="inline-block text-lime-500 text-2xl m-5 hover:scale-125 transition duration-300" />
                <h2 className="text-sm md:text-lg text-white mb-6 max-w-screen-xl mx-auto">
                  10, Agrawal Mall, Sarkhej - Gandhinagar Highway,
                  <br />
                  Gota, Ahmedabad, Gujarat 380060
                </h2>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex justify-center ">
            <div className="containter flex justify-center flex-col md:w-3/4">
              <Form />
              <div className="bg-white w-full p-10 sticky mt-[-100px]  md:mt-[-85px] white-panel  rounded-xl"></div>
            </div>
          </div>
        </div>
      </div>
      
      {/* <div className='bg-white py-2 md:py-8'>
          <div className='max-w-screen-2xl mx-auto'>
            <div className='w-full p-2 md:p-8 text-center'>
                <SectionTitle text="Read FAQs" color="text-black"/>
            </div>
            <div className='py-2 md:py-6 px-10 max-w-screen-2xl mx-auto'>
              {accordionData.map(({ title, content }) => (
                <Accordion title={title} content={content}/>
              ))}
            </div>
          </div>
        </div> */}
      <div className="w-full p-4 md:p-8 bg-black text-center">
        <div className="w-28 md:w-40 mx-auto my-4 md:my-6">
          <img
            src="/images/CAXTRA.png"
            alt="Logo"
            className="h-full w-full cursor-pointer"
          />
        </div>
        <h2 className="text-sm md:text-base text-ghostwhite mb-6 font-light	pointer-events-none max-w-screen-xl mx-auto">
          It's time to take your business digital world
        </h2>
        {/* <h2 className="text-sm md:text-base text-antiquewhite font-light	mb-6 pointer-events-none max-w-screen-xl mx-auto flex items-center justify-center gap-3">
              <span>Terms of Service</span> 
              <span>Terms & Conditions</span> 
              <span>Privacy Policy</span>
              <span>Refund & Cancellation</span>
            </h2> */}
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 sm:w-full">
            © {new Date().getFullYear()}{" "}
            <a
              href="https://caxtra.com"
              className="hover:text-green-500 transition duration-300"
            >
              Caxtra Tech
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </div>
  );
};

export default Lead;
