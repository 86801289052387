import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import SocialIcons from "./common/SocialIcons";

const menuItems = ["Home","About","Services","Contact"];

const Header = () => {
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

    return (
      <header className="w-full max-w-screen-2xl mx-auto shadow-md">
      <nav className="w-full flex md:flex-row items-center justify-between p-4 sm:p-10 max-w-1980 mx-auto">
        <div className='w-72 mr-4'>
          <img
            src="/images/CAXTRA.png"
            alt='Logo'
            onClick={() => navigate("/")}
            className='h-full w-full cursor-pointer'
          />
        </div>
        <button className="block sm:hidden" onClick={toggleMenu}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            {menuOpen ? (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            ) : (
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
            )}
          </svg>
        </button>
        <ul className={`${
          menuOpen ? 'flex flex-col sm:flex-row sm:items-center' : 'hidden'
        } sm:flex justify-between items-center w-full sm:w-auto text-lg flex-wrap`}>
          {menuItems.map((menu) => (
            <li key={menu} className="mr-0 mt-3 md:mr-4 md:mt-0 list-none">
              <NavLink
                className="text-white hover:text-green-500 transition duration-300 no-underline block sm:inline-block"
                to={menu === "Home" ? "/" : `/${menu.toLowerCase()}`}>
                {menu}
              </NavLink>
            </li>
          ))}
          <li className="mr-0 mt-3 md:mr-4 md:mt-0 list-none">
            <SocialIcons />
          </li>
        </ul>
      </nav>
    </header>
      );
}

export default Header
