    import React, { useState } from 'react'
import SectionTitle from './SectionTitle';

    const Slider = ({ logos }) => {

        const [currentIndex, setCurrentIndex] = useState(0);
        
      const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % logos.length);
      };
    
      const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + logos.length) % logos.length);
      };

    return (
        <div className='w-full text-center px-10 pt-20 pb-20'>
        <p>OUR CLIENTS</p>
        <SectionTitle text="Meet our happy clients"/>
        <div className="flex items-center justify-center gap-4 pt-10 ">
            {logos.length > 1 && <button onClick={prevSlide}>&#10094;</button>}
            {logos.map((logo, index) => (
                <div key={index} className={`w-60 transform transition-transform duration-300`}>
                    <img
                        className='h-[120px]'
                        src={logo}
                        alt={`Logo ${index + 1}`}
                    />
                </div>
            ))}
            {logos.length > 1 && <button onClick={nextSlide}>&#10095;</button>}
        </div>
        </div>
    )
    }

    export default Slider
