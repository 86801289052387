import React, { useEffect } from 'react';

const Form = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    script.async = true;

    script.onload = () => {
      if (!window.hbspt) {
        console.error("HubSpot Forms script failed to load.");
        return;
      }

      const formContainer = document.getElementById("hubspotForm");
      if (!formContainer) {
        console.error("Form container element not found.");
        return;
      }

      const whitePanel = document.getElementsByClassName("white-panel");

      window.hbspt.forms.create({
        region: "na1",
        portalId: "45573183",
        formId: "ff26d12f-abb7-4e5d-a424-79fe75392995",
        target: "#hubspotForm",
        onFormSubmit: function($form) {
            whitePanel[0].classList.add('hidden');
          },
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="hubspotForm" className='px-5 md:px-10 py-5 bg-white rounded-xl'></div>;
};

export default Form;