import React from 'react';
import ImageBox from '../components/common/ImageBox';
import MainSection from '../components/common/MainSection';
import OurServices from '../components/common/OurServices';
import ScrollToTop from '../components/common/ScrollToTop';
import Slider from '../components/common/Slider';
import ClientTestimonial from "../components/common/ClientTestimonial";
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import SectionTitle from "../components/common/SectionTitle";
import Button from "../components/common/Button";
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Sparkles } from '@react-three/drei';

const logos = ['/images/ro-care-logos.png','/images/highness.jpeg'];

const testimonials = [
  { image: '/images/review_star.svg',
    companyName: 'RO Care Help',
    owner: 'Pankaj Kumar, Mumbai',
    description: 'The caring team brought my vision to life. Beautiful, user-friendly site, with clear communication & helpful tweaks along the way. My care-focused site feels genuine & trustworthy now. Highly recommend!'
  },
];

const Home = () => {
  const navigate = useNavigate();


  return (
    <div className='w-full'> 
     <Helmet>
        <title>Caxtra - Your Trusted IT Services Provider</title>
        <meta name="description" content="Caxtra is a leading IT company offering custom software development, website design, eCommerce solutions, SEO, and more. Contact us for innovative IT services tailored to your business needs." />
        <meta name="keywords" content="Caxtra, Caxtra tech, IT company, software development, website design, eCommerce, SEO, IT services" />
        <meta name="author" content="Caxtra Tech" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <MainSection 
        title="Empower Your Digital Presence:"
        text="Elevate with Custom Websites, Innovative Software, and Captivating Designs for Unparalleled Business Success"
        buttonName="Let’s Connect !"
        sectionImage="/images/banner_featured_image.png"
        link="https://wa.me/6353107643?text=Hello%2C%20I'm%20interested%20in%20connecting%20with%20Caxtra."
      />
       {/* <div className="w-full p-8 md:p-14 bg-black text-center">
        <SectionTitle
          text="Website Design & Development Services Starting At only 9999/- + 1 Time Free SEO"
          color="text-white"
        />
        <h2 className="text-sm md:text-lg text-white mb-6 pointer-events-none max-w-screen-xl mx-auto">
          Level up your online presence with a website that converts. We design
          websites that are beautiful, functional, and drive results. Get found
          by more customers with a mobile-friendly and search engine optimized
          website that showcases your brand and compels visitors to take action.
        </h2>
        <button
          className='py-4 px-8 m-2 rounded focus:outline-none text-sm md:text-base font-normal bg-green-600 hover:bg-green-500 text-white'
          onClick={() => navigate("/website-design-and-development-lead-generation")}
        >See Details</button>
      </div> */}
      <div className='h-[600px]	relative' >
        <Canvas >
          <color attach="background" args={['black']} />
          <OrbitControls enableZoom={false} />
          <Sparkles size={ 6 } scale={[20,20,20]} count={300} color="rgb(22, 163, 74)" />
        </Canvas>
        <div className="w-full text-center absolute top-40 flex items-center justify-center flex-col">
        <SectionTitle
          text="Website Design & Development Services Starting At only 4999/- + 1 Time Free SEO"
          color="text-white"
        />
        <h2 className="text-sm md:text-lg text-white mb-6 pointer-events-none max-w-screen-xl mx-auto">
          Level up your online presence with a website that converts. We design
          websites that are beautiful, functional, and drive results. Get found
          by more customers with a mobile-friendly and search engine optimized
          website that showcases your brand and compels visitors to take action.
        </h2>
        <button
          className='py-4 px-8 m-2 rounded focus:outline-none text-sm md:text-base font-normal bg-green-600 hover:bg-green-500 text-white'
          onClick={() => navigate("/website-design-and-development-lead-generation")}
        >See Details</button>
      </div>
      </div>
      {/* <div className='w-full shadow-custom' /> "*/}
      <div className='w-full justify-between p-10 flex flex-wrap gap-6 my-18 max-w-screen-2xl mx-auto '>
        <ImageBox 
          textAlign="center"
          image="/images/icon_easy_customize.svg"
          title="Customized Solutions for Diverse Needs"
          text="Offering tailor-made website development to suit the unique requirements of each client, ensuring a personalized and effective online presence."
        />
        <ImageBox
          image="/images/icon_mobile_first.svg"
          title="Seamless User Experience Across Devices"
          text="Prioritizing a mobile-first approach to design, guaranteeing that websites not only look great on all devices but also provide a smooth and consistent user experience."
        />
        <ImageBox 
          image="/images/icon_pagebuilder.svg"
          title="Effortless Content Management"
          text="Streamlining website updates and edits with a user-friendly content management system, empowering clients to effortlessly manage website without the need for expertise."
        />
      </div>
      <MainSection 
        smallTitle="EMBARK ON OUR STARTUP JOURNEY:"     
        title="Software, Website Development Company in India"
        text="We’re a leading software and website development company in India, headquartered in Ahmedabad. Our passion is to create game-changing digital solutions. From custom software to eye-catching websites, we’re dedicated to pushing the boundaries of technology and design. Join us as we redefine success for startups in the digital world."
        buttonName="Read More"
        sectionImage="/images/man_with_laptop.svg"
      />
      <OurServices />
      <Slider logos={logos} logo/>
      <ClientTestimonial testimonials={testimonials}/>
      <ScrollToTop />
    </div>
  )
}

export default Home
