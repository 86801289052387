import React from 'react'
import OurServices from '../components/common/OurServices'

const Service = () => {
  return (
    <div className='w-full'>
      <OurServices />
    </div>
  )
}

export default Service
