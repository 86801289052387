import React, { useRef, useState } from 'react';
import Tilt from 'react-parallax-tilt';

const ImageBox = ({ image, title, text, textAlign, lead }) => {
  const getTextAlignmentClass = () => {
    if (textAlign === 'left') {
      return 'text-left items-start';
    } else if (textAlign === 'right') {
      return 'text-right items-end';
    } else {
      return 'text-center items-center'; 
    }
  };

  const [isHovered, setIsHovered] = useState(false);
  const touchLightRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseMove = (e) => {
    const offsetX = e.nativeEvent.offsetX;
    const offsetY = e.nativeEvent.offsetY;

    touchLightRef.current.style.cssText = `top: ${offsetY}px; left: ${offsetX}px; box-shadow: 0px 0px 250px 150px rgba(47, 133, 90, 0.6);`;
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    touchLightRef.current.style.cssText = `top: -100%; left: -100%;`;
  };

  const isLead = () => lead ? "w-20" : '';

  return (
      <div className={`w-full md:w-[31%] transition duration-300`}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
      >
        <Tilt 
          glareEnable={true}
          glareMaxOpacity={0.25}
          glareColor='#87CEEB'
          tiltMaxAngleX={5}
          tiltMaxAngleY={5}
        >
        <div className={`relative py-10 w-full px-6 bg-gray-800 border border-gray-600 overflow-hidden rounded-lg flex flex-col flex-1 gap-2 ${getTextAlignmentClass()}`}>
          <div className={`mb-6 pointer-events-none ${isLead()}`}>
            <img
              className="w-full h-full object-contain object-center rounded-lg"
              src={image}
              alt="service logo"
            />
          </div>
          <h2 className="text-1xl lg:text-2xl xl:text-3xl font-bold text-white mb-6 pointer-events-none">{title}</h2>
          <p className="text-sm lg:text-base xl:text-lg text-white mb-7 pointer-events-none">
            {text}
          </p>
          <div ref={touchLightRef} className={`absolute touch-light ${isHovered ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}></div>
        </div>
        </Tilt>
      </div>
  );
};

export default ImageBox;


