import React, { useState } from 'react';

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="border border-gray-300 rounded-md mb-4">
      <div
        className="flex justify-between items-center bg-gray-200 px-4 py-2 cursor-pointer"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="text-lg font-semibold text-gray-800">{title}</div>
        <div className='text-black'>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && (
        <div className="border-t border-gray-300 px-4 py-2">
          <p className="text-gray-800">{content}</p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
