import React from "react";
import SocialIcons from "./common/SocialIcons";
import { NavLink, useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="bg-[#0c0f17] k p-10">
      <div className="mx-auto max-w-screen-2xl w-full p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between items-start">
          <div className="mb-6 md:mb-0 w-full md:w-1/3 md:mr-10">
            <a href="https://caxtra.com/" className="flex items-center mb-5">
              <img src="/images/CAXTRA.png" className="" alt="Caxtra Logo" />
            </a>
            <p className="text-gray-300 dark:text-gray-400 font-medium">
              Elevate with Custom Websites, Innovative Software, and Captivating
              Designs for Unparalleled Business Success!
            </p>
            <div className="mt-4">
              <SocialIcons />
            </div>
          </div>
          <div className="w-full md:w-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 lg:gap-32 gap-5">
              <div>
                <h2 className="mb-4 text-xl font-semibold text-gray-300 uppercase dark:text-white">
                  Services
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      Website Design
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      eCommerce
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      Web Development
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      SEO
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      Custom Software Development
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      Social Media Marketing
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-4 text-xl font-semibold text-gray-300 uppercase dark:text-white">
                  Quick Menu
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-2">
                    <NavLink
                      to="/about"
                      className="hover:text-green-500 transition duration-300"
                    >
                      About us
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      Blog
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      Our Team
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      Career
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-4 text-xl font-semibold text-gray-300 uppercase dark:text-white">
                  Legal
                </h2>
                <ul className="text-gray-500 dark:text-gray-400 font-medium">
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li className="mb-2">
                    <NavLink className="hover:text-green-500 transition duration-300">
                      Terms & Conditions
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-300 dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400 sm:w-full">
            © {new Date().getFullYear()}{" "}
            <a
              href="https://caxtra.com"
              className="hover:text-green-500 transition duration-300"
            >
              Caxtra Tech
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
