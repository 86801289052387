import React, { useRef, useState } from 'react';
import Tilt from 'react-parallax-tilt';

const Testimonial = ({ image, companyName, owner, description, textAlign }) => {
  const getTextAlignmentClass = () => {
    if (textAlign === 'left') {
      return 'text-left items-start';
    } else if (textAlign === 'right') {
      return 'text-right items-end';
    } else {
      return 'text-center items-center'; 
    }
  };

  const [isHovered, setIsHovered] = useState(false);
  const touchLightRef = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseMove = (e) => {
    const offsetX = e.nativeEvent.offsetX;
    const offsetY = e.nativeEvent.offsetY;

    touchLightRef.current.style.cssText = `top: ${offsetY}px; left: ${offsetX}px; box-shadow: 0px 0px 250px 150px rgba(47, 133, 90, 0.6);`;
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    touchLightRef.current.style.cssText = `top: -100%; left: -100%;`;
  };

  return (
      <div className={`w-full lg:w-[30%] lg:min-w-90 transition duration-300`}
          onMouseEnter={handleMouseEnter}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
      >
        <Tilt 
          glareEnable={true}
          glareMaxOpacity={0.25}
          glareColor='#87CEEB'
          tiltMaxAngleX={5}
          tiltMaxAngleY={5}
        >
        <div className={`relative py-10 w-full px-6 bg-gray-800 border border-gray-600 overflow-hidden rounded-lg flex flex-col flex-1 gap-2 ${getTextAlignmentClass()}`}>
          <h2 className="text-2xl md:text-3xl font-bold text-white mb-3 pointer-events-none">{companyName}</h2>
          <h3 className="text-xl md:text-xl font-bold text-white mb-3 pointer-events-none">{owner}</h3>
          <img src={image} className='mb-2'/>
          <p className="text-sm md:text-lg text-white mb-7 pointer-events-none">
            {description}
          </p>
          <div ref={touchLightRef} className={`absolute touch-light ${isHovered ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}></div>
        </div>
        </Tilt>
      </div>
  );
};

export default Testimonial;


