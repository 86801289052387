import React from "react";
import Button from "./Button";

const MainSection = ({
  title,
  text,
  buttonName,
  sectionImage,
  leftImage,
  link,
  smallTitle,
  lead,
}) => {
  const order = leftImage ? "order-1" : "order-0";
  // const imagePosition = leftImage ? "md:object-left" : "md:object-right";
  const imagePosition = '';
  
  const getTextColor = () => (lead ? "text-black" : "text-white");

  return (
    <section className="md:flex md:items-center px-10 py-11 md:py-24 max-w-screen-2xl mx-auto ">
      <div className={`w- full md:w-1/2 ${order}`}>
        {smallTitle && (
          <h2
            className={`text-sm md:text-base lg:text-lg dont-smal mb-1 ${getTextColor()}`}
          >
            {smallTitle}
          </h2>
        )}
        <h2
          className={`text-2xl md:text-3xl font-medium  mb-6 ${getTextColor()}`}
        >
          {title}
        </h2>
        <p className={`text-sm md:text-base lg:text-lg mb-7 ${getTextColor()}`}>
          {text.split("break").length > 1 ? (
            <>
              {text.split("break").map((line, index) => (
                <React.Fragment key={index}>
                  <li>{line}</li>
                  {text.split("break").length - 1 > index  ? (<><br /></>):(<></>)}
                </React.Fragment>
              ))}
            </>
          ) : (
            <>{text}</>
          )}
        </p>
        {buttonName && <Button text={buttonName} color="green" link={link} />}
      </div>

      <div className="w-full md:w-1/2 lg:mt-0 mt-8 h-96">
        <img
          className={`w-full h-full object-contain sm:object-center ${imagePosition} rounded-lg`}
          src={sectionImage}
          alt="banner"
        />
      </div>
    </section>
  );
};

export default MainSection;
