import React from 'react'
import { FaFacebookSquare, FaInstagram, FaLinkedin } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";

const socialIcons = [
  {
    url:"https://www.instagram.com/caxtra.tech",
    icon: <FaInstagram />
  },
  {
    url:"https://www.linkedin.com/company/caxtra-tech",
    icon: <FaLinkedin />
  },
  {
    url:"https://www.facebook.com/caxtra.tech",
    icon: <FaFacebookSquare />
  },
  {
    url:"https://twitter.com/caxtra_tech",
    icon: <RiTwitterXLine />
  },
];

const SocialIcons = () => {
  return (
    <div className='flex justify-start gap-2 items-center flex-wrap'>
      {socialIcons.map((icon,i)=><a href={icon.url} key={i} className='text-white text-lg hover:text-green-500 transition duration-300'>{icon.icon}</a>)}
    </div>
  )
}

export default SocialIcons
