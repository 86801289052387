import React from 'react';

const Button = ({ text, color,link }) => {

    const colorVariants = {
        blue: 'bg-blue-600 hover:bg-blue-500 text-white',
        green: 'bg-green-600 hover:bg-green-500 text-white',
        red: 'bg-red-500 hover:bg-red-400 text-white',
        yellow: 'bg-yellow-300 hover:bg-yellow-400 text-black',
      }

  const baseClasses = 'py-4 px-8 m-2 rounded focus:outline-none text-sm md:text-base font-normal';

  const buttonClasses = `${baseClasses} ${colorVariants[color]}`

  return (
   <a href={link} target='_blank'>
     <button className={buttonClasses}>
      {text}
    </button>
   </a>
  )
};

export default Button;
