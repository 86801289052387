import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import RootLayout from "./components/rootLayout";
import Home from "./pages/Home";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Service from "./pages/Service";
import Lead from "./pages/Lead";
import { Fragment } from "react";


const router = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      <Route
        path="/"
        element={<RootLayout />}
        errorElement={<p>Error Accured!</p>}
      >
        <Route index element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Service />} />
        {/* <Route path="/blog" element={<Blog />} /> */}
        <Route path="/contact" element={<Contact />} />
      </Route>
      <Route path="website-design-and-development-lead-generation" element={<Lead />} />
    </Fragment>

  )
);

function App() {
  return (
     <RouterProvider router={router} />
  );
}

export default App;
