import React from 'react'
import ImageBox from './ImageBox'
import SectionTitle from './SectionTitle';

const services = [
  {
    image:"/images/icon_strategy.svg",
    title:"Website Design",
    text:"Create visually appealing and intuitive website designs that captivate audiences, ensuring a seamless and memorable user experience across various devices.",
  },
  {
    image:"/images/icon_ecommerce.svg",
    title:"eCommerce",
    text:"Build robust and secure e-commerce platforms, enabling businesses to establish or enhance their online presence with features like secure payment gateways and etc.",
  },
  {
    image:"/images/icon_screen.svg",
    title:"Web Development",
    text:"Craft dynamic and functional websites that align with your brand, leveraging cutting-edge technologies to ensure a modern and user-friendly online presence.",
  },
  {
    image:"/images/icon_data.svg",
    title:"SEO",
    text:"Elevate your online visibility with strategic SEO services, optimizing your website to rank higher in search engine results, attract targeted organic traffic, and driving tangible results for your business.",
  },
  {
    image:"/images/icon_marketing.svg",
    title:"Custom Software Development",
    text:"Tailor-made software solutions designed to address specific business needs, providing scalable and efficient applications to enhance overall operational effectiveness.",
  },
  {
    image:"/images/icon_social.svg",
    title:"Social Media Marketing",
    text:"Amplify your brand presence through social media marketing, engaging and connecting with your audience across platforms to increase brand awareness, drive traffic, and foster customer loyalty.",
  },
];

const OurServices = () => {
  return (
    <div className='w-full text-center p-10 pb-20 bg-black'>
      <p className='mt-10'>SERVICES</p>
      <SectionTitle text="Our Services"/>
      <div className='w-full flex flex-wrap justify-evenly gap-4 lg:gap-8 my-4 max-w-screen-2xl mx-auto '>
        {services.map((service,i)=>(
            <ImageBox 
              key={i}
              textAlign="left"
              image={service.image}
              title={service.title}
              text={service.text}
            />
          ))}
      </div>
    </div>
  )
}

export default OurServices
