import React from "react";

const SectionTitle = ({ text, color = "text-white" }) => {
  return (
    <h1 className={`text-3xl lg:text-4xl font-medium my-6 md:my-10 text-balance	${color}`}>
      {text}
    </h1>
  );
};

export default SectionTitle;
